const defaultHead = {
    title: 'Convert Videos to Storyboards',
    description: 'Convert videos into detailed storyboards and notes with StoryFolder. Extract slides, production notes, and more. Start for free today!',
    robots: 'index,follow',
    'og:type': 'website',
    'og:locale': 'en_US',
    'og:site_name': 'StoryFolder',
    'twitter:card': 'summary'
}

export default {
    watch: {
        head:{
            deep: true,
            immediate: true,
            handler: head => {
                head = Object.assign({}, defaultHead, head || {});

                // set default values for titles
                head['og:title'] = head.title;
                head['twitter:title'] = head.title;

                // set default values for descriptions
                head['og:description'] = head.description;
                head['twitter:description'] = head.description;


                // append site name to titles
                if(!head.title.includes('StoryFolder')){
                    head.title += ' | StoryFolder';
                }
                // prepend site name to og:title
                if(!head['og:title'].includes('StoryFolder')){
                    head['og:title'] = 'StoryFolder: ' + head['og:title'];
                }
                if(!head['twitter:title'].includes('StoryFolder')){
                    head['twitter:title'] = 'StoryFolder: ' + head['twitter:title'];
                }
                head['og:url'] = window.location.href;


                // loop through each property in the head object
                for (let property in head) {
                    if (property === 'title') {
                        document.title = head.title || '';
                    } else {
                        // find or create a meta tag for this property
                        let element = document.querySelector(`meta[property='${property}']`) ||
                            document.querySelector(`meta[name='${property}']`);

                        if (!element) {
                            element = document.createElement('meta');
                            if(property.startsWith('og:') || property.startsWith('twitter:')) {
                                element.setAttribute('property', property);
                            } else {
                                element.setAttribute('name', property);
                            }
                            document.getElementsByTagName('head')[0].appendChild(element);
                        }

                        // set the value of the content attribute to the value from head
                        element.setAttribute('content', head[property]);
                    }
                }
            }
        }
    }
}
