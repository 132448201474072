import { render, staticRenderFns } from "./PageDesktop.vue?vue&type=template&id=78233d49&scoped=true"
import script from "./PageDesktop.vue?vue&type=script&lang=ts"
export * from "./PageDesktop.vue?vue&type=script&lang=ts"
import style0 from "./PageDesktop.vue?vue&type=style&index=0&id=78233d49&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78233d49",
  null
  
)

export default component.exports