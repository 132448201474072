<template>
  <AppPage :fix-header="true">
    <TheHeroVideoSection />
    <TheVideoSection v-if="false"/>
    <TheTestimonialSection />
    <TheMetadataSection />
    <TheUseCaseSection v-if="false"/>
    <section class="halfies">
      <div style="background: #000; text-align: center; padding: 3em 2em; background-image:url('/api/storyboards/646feaea6276ac594213adf5/Shot-3.png'); background-size: cover; background-position: center center; ">
        <div>
          <h2>View an Example</h2>
          <h1>Dollar Shave Club</h1>
          <p style="max-width: 800px; margin:1em auto; color: white;">
            Hold onto your monocles, ladies and gents, and dive into the heart of the pop culture juggernaut, that is the Dollar Shave Club ad from 2012.
          </p>
          <Button to="/b/646feaea6276ac594213adf5" icon="view-grid" :has-chevron="true" class="primary rounded">
            View the Storyboard
          </Button>
        </div>
      </div>
      <div style="background: #000; text-align: center; padding: 3em 2em; background-image:url('/api/storyboards/647269155473255d1351bfa7/Shot-13.png'); background-size: cover; background-position: center center; ">
        <div>
          <h2>View an Example</h2>
          <h1>Game of Thrones</h1>
          <p style="max-width: 800px; margin:1em auto;">
            Dissect complex narrative devices and cinematic techniques from one of Game of Thrones most dramatic and memorable scenes: the Sept of Baelor explosion scene.
          </p>
          <Button to="/b/647269155473255d1351bfa7" icon="view-grid" :has-chevron="true" class="primary rounded">
            View the Storyboard
          </Button>
        </div>
      </div>
    </section>
    <TheIntroSection />
    <TheExportSection />
    <TheFeaturetteSection v-if="false"/>
    <TheThirdPartyToolSection v-if="false"/>
    <TheExperienceSection />
    <TheSystemRequirementsSection />
  </AppPage>
</template>

<script lang="ts">
import AppPage from "@/components/organisms/AppPage.vue";
import TheHeroVideoSection from "@/components/organisms/TheHeroVideoSection.vue";
import TheIntroSection from "@/components/organisms/TheIntroSection.vue";
import TheFeaturetteSection from "@/components/organisms/TheFeaturetteSection.vue";
import TheExperienceSection from "@/components/organisms/TheExperienceSection.vue";
import TheSystemRequirementsSection from "@/components/organisms/TheSystemRequirementsSection.vue";
import TheTestimonialSection from "@/components/organisms/TheTestimonialSection.vue";
import TheThirdPartyToolSection from "@/components/organisms/TheThirdPartyToolSection.vue";
import TheMetadataSection from "@/components/organisms/TheMetadataSection.vue";
import TheExportSection from "@/components/organisms/TheExportSection.vue";
import TheUseCaseSection from "@/components/organisms/TheUseCaseSection.vue";
import TheVideoSection from "@/components/organisms/TheVideoSection.vue";
import Button from "@/components/atoms/Button.vue";
import pageHeadMixin from "@/components/mixins/pageHeadMixin.js";


export default {
  mixins: [pageHeadMixin],
  components: {
    Button,
    TheVideoSection,
    TheExportSection,
    TheMetadataSection,
    AppPage,
    TheHeroVideoSection,
    TheIntroSection,
    TheFeaturetteSection,
    TheExperienceSection,
    TheSystemRequirementsSection,
    TheTestimonialSection,
    TheThirdPartyToolSection,
    TheUseCaseSection
  },
  watch: {
    '$store.state.user.user.email':{
      immediate: true,
      handler(){

      }
    }
  }
}
</script>

<style scoped lang="less">
  @import '../less/variables';


  video{
    width: 100%;
    outline: 0;
    text-align: center;
  }

  @media(min-width: @thirdbreakpoint){
    section.halfies{
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 6em 4em;
      gap: 2em;
      background: @white;
      > div{
        border-radius: 1em;
      }
    }
  }

</style>
